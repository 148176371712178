<template>
    <div v-bind:style="chartBoxStyle">
        <div ref="chart_box" class="map_box_comp"></div>
    </div>
</template>
<script type="text/javascript">
// import { ShortForprovince } from '@/common/js/location/acronym.js'
export default {
    props: {
        option: {
            type: Array,
            default: function() {
                return []
            },
        },
    },
    data() {
        return {
            chartBoxStyle: {},
            dataList: [],
        }
    },
    created() {
        this.$nextTick(this.setStyle)
    },
    watch: {
        option: function(val) {
            console.log(val)
            this.setDataList(val)
        },
        dataList: function() {
            this.setChart()
        },
    },
    methods: {
        setDataList(list) {
            var valueList = []
            // list.forEach(item => {
            // 	//获取定位名称
            // 	var new_point = new BMap.Point(item.lng, item.lat);
            // 	var gc = new BMap.Geocoder(); //初始化，Geocoder类

            // 	gc.getLocation(new_point, function(rs) { //getLocation函数用来解析地址信息，分别返回省市区街等
            // 		var addComp = rs.addressComponents;
            // 		var province = addComp.province;
            // 		if (!l.includes(province)) {
            // 			console.log(province);
            // 			l.push(province);
            // 			valueList.push({
            // 				name: province.replace('省', '').replace('市', ''),
            // 				value: 1
            // 			});
            // 			console.log(valueList);
            // 		} else {
            // 			valueList.forEach(one => {
            // 				if (one.name == province.replace('省', '').replace('市', '')) {
            // 					one.value++;
            // 				}
            // 			})
            // 		}
            // 		item.name = province;
            // 	});
            // })
            valueList = list.map(one => {
                // let obj = {
                // 	name: ShortForprovince(one.city).replace('省', '').replace('市', ''),
                // 	sortName: one.province,
                // 	value: one.online,
                // 	total: one.total,
                // };
                return one
            })
            this.dataList = valueList
        },
        setStyle() {
            console.log($(this.$el).width())
            let obj = {}
            let h = $(this.$el).width() * 0.4
            obj.height = h + 'px'
            this.chartBoxStyle = obj
            this.$nextTick(this.setChart)
        },
        setChart() {
            var _this = this

            var geoCoordMap = {}
            /*获取地图数据*/
            var mapFeatures = echarts.getMap('china').geoJson.features
            mapFeatures.forEach(function(v) {
                // 地区名称
                var name = v.properties.name
                // 地区经纬度
                geoCoordMap[name] = v.properties.cp
            })

            var scatterData = function(data) {
                var res = []
                for (var i = 0; i < data.length; i++) {
                    var geoCoord = geoCoordMap[data[i].province]
                    if (geoCoord) {
                        res.push({
                            name: data[i].province,
                            value: geoCoord.concat(data[i].userCount),
                        })
                    }
                }
                return res
            }

            var userChart = echarts.init(this.$refs.chart_box)

            var option = {
                tooltip: {
                    formatter: '',
                    show: false,
                    // triggerOn: 'click',
                },
                geo: {
                    show: true,
                    map: 'china',
                    zoom: 1,
                    top: 10,
                    bottom: 10,
                    aspectScale: 0.9, //长宽比
                    label: {
                        normal: {
                            show: false,
                        },
                        emphasis: {
                            show: false,
                        },
                    },
                    roam: false,
                    itemStyle: {
                        show: false,
                        opacity: 0,
                        normal: {
                            areaColor: '#031525',
                            borderWidth: 3,
                            borderColor: '#00FEFF',
                            shadowColor: 'rgba(3,221,255,0.8)',
                            shadowBlur: 10,
                        },
                    },
                },
                series: [
                    {
                        type: 'map',
                        map: 'china',
                        zoom: 1,
                        top: 10,
                        bottom: 10,
                        data: scatterData(_this.dataList),
                        geoIndex: 1,
                        aspectScale: 0.9, //长宽比
                        showLegendSymbol: false, // 存在legend时显示
                        label: {
                            normal: {
                                show: false,
                            },
                            emphasis: {
                                show: false,
                                textStyle: {
                                    color: '#fff',
                                },
                            },
                        },
                        roam: false,
                        itemStyle: {
                            opacity: 0.5,
                            normal: {
                                areaColor: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 0.5,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#0a267a', // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: '#270b6d', // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                                borderColor: '#0073DA',
                                borderWidth: 1,
                            },
                            emphasis: {
                                areaColor: {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 0.5,
                                    colorStops: [
                                        {
                                            offset: 0,
                                            color: '#0a267a', // 0% 处的颜色
                                        },
                                        {
                                            offset: 1,
                                            color: '#270b6d', // 100% 处的颜色
                                        },
                                    ],
                                    global: false, // 缺省为 false
                                },
                            },
                        },
                        tooltip: {
                            show: false,
                            trigger: 'item',
                            triggerOn: 'click',
                            formatter: function(val) {
                                console.log(val)
                                let data = val.data
                                if (data && data.name) {
                                    return [
                                        data.name,
                                        '---用户数: ' + data.value[2],
                                    ]
                                } else {
                                    return ['该地区没有用户数据']
                                }
                            },
                        },
                    },
                    {
                        name: '散点', //省份的名称以及名称旁边的，根据数据来显示：有数据就显示没有数据就不显示
                        type: 'scatter',
                        coordinateSystem: 'geo',
                        data: scatterData(_this.dataList),
                        symbolSize: function(val) {
                            if (Number(val[2]) > 0) {
                                var size = 10
                                // let winW = $(window).width();
                                // if (winW < window.config.minWidth) {
                                // 	size = 15;
                                // }
                                console.log('显示')
                                return size //固定
                            } else {
                                console.log('不显示')
                                return 0 //不显示
                            }
                        },
                        label: {
                            normal: {
                                formatter: '{b}',
                                position: 'right',
                                show: true,
                            },
                            emphasis: {
                                show: false,
                            },
                        },
                        itemStyle: {
                            normal: {
                                color: '#d8ae08',
                            },
                        },
                        tooltip: {
                            show: true,
                            trigger: 'item',
                            triggerOn: 'mousemove',
                            formatter: function(val) {
                                let data = val.data
                                return ['用户数: ' + data.value[2]]
                            },
                        },
                    },
                ],
            }

            userChart.setOption(option)
            window.onresize = () => {
                let obj = {}
                let h = $(this.$el).width() * 0.4
                obj.height = h + 'px'
                this.chartBoxStyle = obj
                this.$nextTick(() => {
                    userChart.resize()
                })
            }
        },
    },
}
</script>
<style type="text/css" lang="scss" scoped="map_box_home">
.map_box_comp {
    width: 100%;
    height: 100%;
}
</style>