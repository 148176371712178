<template>
    <div class="home">
        <div class="top_time">当前时间 ：{{ nowTime }}</div>
        <!-- <div class="top_data">
            <div class="data_item" style="background-color:#ffcc00">
                <div class="item_top">
                    <i class="jy-diannao"></i> PC应用</div>
                <div class="item_buttom">{{dataList.pcAppCount}}</div>
            </div>
            <div class="data_item" style="background-color:#ff3300">
                <div class="item_top">
                    <i class="jy-shouji"></i> 移动应用</div>
                <div class="item_buttom">{{dataList.moveAppCount}}</div>
            </div>
            <div class="data_item" style="background-color:#3333ff">
                <div class="item_top">
                    <i class="jy-chengshi"></i> 已有机构</div>
                <div class="item_buttom">{{dataList.orgCount}}</div>
            </div>
            <div class="data_item" style="background-color:#333333">
                <div class="item_top">
                    <i class="jy-renyuan"></i> 已有用户</div>
                <div class="item_buttom">{{dataList.memberCount}}</div>
            </div>
        </div> -->
        <jy-homesum :list="summary" class="text_box"></jy-homesum>
        <div class="map">
            <screenmap :option="list"></screenmap>
        </div>
    </div>
</template>
<script>
import screenmap from '@/components/home/map'
export default {
    data() {
        return {
            nowTime: '',
            time: null,
            list: [],
            // dataList: {
            //     pcAppCount: '',
            //     moveAppCount: '',
            //     orgCount: '',
            //     memberCount: '',
            // },
            summary: [
                {
                    icon: require('@/assets/home/PC.png'),
                    info: 'PC应用',
                    num: '',
                },
                {
                    icon: require('@/assets/home/phone.png'),
                    info: '移动应用',
                    num: '',
                },
                {
                    icon: require('@/assets/home/institutions.png'),
                    info: '已有机构',
                    num: '',
                },
                {
                    icon: require('@/assets/home/user.png'),
                    info: '已有用户',
                    num: '',
                },
            ],
        }
    },
    created() {
        this.setTime()
        this.getlist()
        this.getmaplist()
    },
    beforeDestroy() {
        this.nowTime = null
    },
    components: {
        screenmap,
    },
    methods: {
        setTime() {
            this.time = setInterval(() => {
                this.nowTime = new Date().Format('yyyy-MM-dd hh:mm:ss')
            }, 1000)
        },
        getlist() {
            let url = '/home/queryHeadData'
            this.$http.post(url).then(res => {
                this.summary[0].num = res.detail.pcAppCount
                this.summary[1].num = res.detail.moveAppCount
                this.summary[2].num = res.detail.orgCount
                this.summary[3].num = res.detail.memberCount
            })
        },
        getmaplist() {
            let url = '/home/queryMapOnlineData'
            this.$http.post(url).then(res => {
                this.list = res.detail
            })
        },
    },
}
</script>
<style lang="scss" scoped="home">
.home {
    .top_time {
        font-size: 22px;
    }
    .top_data {
        display: flex;
        justify-content: space-around;
        margin: 20px 0;
        .data_item {
            width: 250px;
            height: 125px;
            border: 1px solid white;
            color: #fff;
            border-radius: 10px;
            display: inline-flex;
            flex-direction: column;
            align-items: center;
            font-size: 25px;
            font-weight: bold;
            .item_top {
                margin: 15px 0;
                .jy-diannao,
                .jy-shouji,
                .jy-chengshi,
                .jy-renyuan {
                    font-size: 30px;
                }
            }
            .item_buttom {
                font-size: 40px;
            }
        }
    }
    .map {
    }
    .text_box {
        margin: 15px 0;
    }
}
</style>
